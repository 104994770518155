import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Onboardings, ONBOARDING_STATUS_BREAKS } from 'services/onboardings';
import { I18n } from 'common/i18n';

export class OnboardingStatus {
    static inject = [EventAggregator, Security, Onboardings, I18n];
    _ea;
    _security;
    _onboardings;
    _i18n;

    showTeamOptions = false;
    forAgency = false;
    statusBreaks = ONBOARDING_STATUS_BREAKS;
    statusR = true;
    statusY = true;
    statusG = false;

    chartData;

    filters = [
        { value: '', keys: ['agent.firstName', 'agent.lastName', 'agent.fullName', 'currentStep', 'currentSubstep', 'statusColor'] },
        { value: 'ry', custom: this.statusColorFilter },
    ];
    filteredEventKey = 'lpfn-onboarding-status-filtered';

    _handlers = [];

    constructor(ea, security, onboardings, i18n) {
        this._ea = ea;
        this._security = security;
        this._onboardings = onboardings;
        this._i18n = i18n;
        this.showTeamOptions = this._security.isAgency();
        this.forAgency = this._security.isAgency();
    }

    attached() {
        this._handlers.push(this._ea.subscribe(this.filteredEventKey, () => this.onboardingsFiltered()));
        this._load();
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    toggleTeamOptions(forAgency) {
        this.forAgency = forAgency;
        this._load();
    }

    toggleStatusFilter(color) {
        if (this.filters[1].value.indexOf(color) >= 0) {
            this.filters[1].value = this.filters[1].value.replace(color, '');
        } else {
            this.filters[1].value += color;
        }
        this.statusR = this.filters[1].value.indexOf('r') >= 0;
        this.statusY = this.filters[1].value.indexOf('y') >= 0;
        this.statusG = this.filters[1].value.indexOf('g') >= 0;
    }

    statusColorFilter(filterValue, row) {
        if (!filterValue) return false;
        if (filterValue.indexOf(row.statusColor) >= 0) return true;
        return false;
    }

    onboardingsFiltered() {
        this._loadCharts();
    }

    async _load() {
        if (this.loading) return;
        try {
            this.loading = true;
            this.status = await this._onboardings.teamStatus(this.forAgency);
            this._loadCharts();
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    _loadCharts() {
        this.chartData = {
            steps: this.status.steps,
            countsRYG: this.status.countsRYG,
            onboardings: this.dgOnboardings,
        };
    }
}
