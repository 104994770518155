import { bindable } from 'aurelia-framework';
import { Onboardings } from 'services/onboardings';
import { Notifier } from 'common/ui';
import { OnboardingStep } from './onboarding-step';
import moment from 'moment';

export class AccessSuranceBay extends OnboardingStep {
    static inject = [Element, Onboardings, Notifier];
    @bindable status;
    @bindable step;

    _notifier;

    showSso = false;
    autoSso = true;
    hasAccessedSB = false;

    constructor(element, onboardings, notifier) {
        super(element, onboardings);
        this._notifier = notifier;
    }

    attached() {
        this.hasAccessedSB = this.step.data && this.step.data.accessedDate || false;
        this.autoSso = !this.hasAccessedSB;
        this.showSso = this.hasAccessedSB;
        this._load();
    }

    async backStep() {
        try {
            const status = await this._onboardings.backStep(this.step);
            this.stepCompleted(status);
        } catch (err) {
            console.log(err);
        }
    }

    async _load() {
        try {
            this.contractDetails = await this._onboardings.contractDetails();
        } catch (err) {
            console.log(err);
        }
    }

    async accessSuranceBay() {
        if (this.working) return;
        try {
            this.working = true;
            this.step.data = { accessedDate: moment().format() };
            this.status = await this.updateProgress(this.step);
            this.hasAccessedSB = true;
            this.showSso = true;
        } catch (err) {
            console.log(err);
        } finally {
            this.working = false;
        }
    }

    async checkStatus() {
        if (this.working) return;
        try {
            this.working = true;
            const status = await this._onboardings.checkContractedStatus();
            const contractedStep = status.template.steps.find(x => x.code === this.step.code && x.platform === this.step.platform);
            const contractedData = JSON.parse(contractedStep.data);
            if (!contractedData.confirmContractedDate) {
                this._notifier.info('onboarding-contracted-check-not-contracted');
                return;
            }
            this.stepCompleted(status);
        } catch (err) {
            console.log(err);
        } finally {
            this.working = false;
        }
    }
}
