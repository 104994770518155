import { bindable } from 'aurelia-framework';
import { Onboardings } from 'services/onboardings';
import { Security } from 'common/security';
import { OnboardingStep } from './onboarding-step';

export class AddProfilePicture extends OnboardingStep {
    static inject = [Element, Onboardings, Security];
    @bindable status;
    @bindable step;

    imageUrl;

    constructor(element, onboardings, security) {
        super(element, onboardings);
        this.security = security;
    }

    attached() {
        this.imageUrl = this.step.metadata;
    }

    async onContinue() {
        await this.updateProgress(this.step);
    }
}
