import { bindable } from 'aurelia-framework';
import { Onboardings } from 'services/onboardings';
import { OnboardingStep } from './onboarding-step';

export class Welcome extends OnboardingStep {
    static inject = [Element, Onboardings];
    @bindable status;
    @bindable step;

    lightboxVM;
    showPlayButton = true;

    constructor(element, onboardings) {
        super(element, onboardings);
    }

    attached() {
        try {
            this.lightboxVM.open(true);
        } catch (err) {
            console.log(err);
        }
    }

    playVideo() {
        try {
            this.videoEl.play();
            this.showPlayButton = false;
        } catch (err) {
            console.log(err);
        }
    }

    lightboxClosed() {
        this.videoEl.pause();
    }

    onVideoEnd() {
        this.lightboxVM.close();
    }

    async onContinue() {
        await this.updateProgress(this.step);
    }
}
