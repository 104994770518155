import { PLATFORM } from 'aurelia-pal';
import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {activationStrategy} from 'aurelia-router';
import {Api} from 'common/server';
import {Security} from 'common/security';
import {Page} from 'common/ui';
import {I18n} from 'common/i18n';

@inject(EventAggregator, Api, Security, Page, I18n)
export class TeamReports {

    reportModel = null;
    reportView = '';
    _params;

    isAgency;

    constructor(eventAggregator, api, security, page, i18n) {
        this._eventAggregator = eventAggregator;
        this._api = api;
        this._security = security;
        this._page = page;
        this._i18n = i18n;

        this.isAgency = this._security.isAgency();
    }

    determineActivationStrategy(){
        return activationStrategy.invokeLifecycle;
    }

    activate(params) {
        this._params = params;
        this.reportType = params.reportType || null;
        this._page.track('members/my-team/reports/my-reports');
        this._showReport();
    }

    attached() {
        $("#my-team-reports-modal").on("hidden.bs.modal", this.clearModal);
    }

    _showReport() {
        switch (this.reportType) {
            case 'recruiting-summary':
                this.showRecruitingSummary();
                break;
            case 'onboarding-summary':
                this.showOnboardingSummary()
                break;
            case 'agency-monthly-bonus':
                this.showAgencyMonthlyBonusReport()
                break;
        }
    }

    onReportButtonClick(type) {
        this.reportType = type;
        this._showReport();
    }

    showRecruitingSummary() {
        this.reportModel = {
            teamSummary: this._params.teamSummary,
            start: this._params.start,
            end: this._params.end
        };
        this.reportView = PLATFORM.moduleName('members/recruiting/summary');
    }

    showOnboardingSummary() {
        this.reportModel = {
            teamSummary: this._params.teamSummary,
            start: this._params.start,
            end: this._params.end
        };
        this.reportView = PLATFORM.moduleName('members/recruiting/onboarding-summary');
    }

    showAgencyMonthlyBonusReport() {
        this.reportModel = {
            month: this._params.month,
        };
        this.reportView = PLATFORM.moduleName('members/agency/reports/agency-monthly-bonus');
    }
}
