import { bindable } from 'aurelia-framework';
import { Security } from 'common/security';
import { Onboardings } from 'services/onboardings';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { OnboardingStep } from './onboarding-step';
import { c } from 'common/common';

export class EnterNpn extends OnboardingStep {
    static inject = [Element, Security, Onboardings, NewInstance.of(ValidationController)];
    @bindable status;
    @bindable step;

    _security;
    _validationController;
    npn;
    residentLicenseState;
    usStates = [];
    residentLicenseNumber;
    licenseFirstName;
    licenseLastName;

    constructor(element, security, onboardings, validationController) {
        super(element, onboardings);
        this._security = security;
        this._validationController = validationController;

        this.usStates = c.Geography.usStates;

        this._validationController.addRenderer(new BootstrapFormValidationRenderer());
        ValidationRules
            .ensure('npn').required()
            .ensure('licenseFirstName').required()
            .ensure('licenseLastName').required()
            .ensure('residentLicenseState').required()
            .ensure('residentLicenseNumber').required()
            .on(this);
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.licenseFirstName = this._security.agent.firstName;
            this.licenseLastName = this._security.agent.lastName;
            this.residentLicenseState = this._security.agent.state;
            const details = await this._onboardings.contractDetails();
            this.npn = details.nationalProducerNumber;
            this.residentLicenseState = details.residentLicenseState;
            this.residentLicenseNumber = details.residentLicenseNumber;
        } catch (err) {
            console.log(err);
        }
    }

    async recordNpn() {
        try {
            const v = await this._validationController.validate();
            if (!v.valid) return;
            this.working = true;

            this.step.data = {
                npn: this.npn,
                licenseFirstName: this.licenseFirstName,
                licenseLastName: this.licenseLastName,
                residentLicenseState: this.residentLicenseState,
                residentLicenseNumber: this.residentLicenseNumber
            };
            await this.updateProgress(this.step);
        } catch (err) {
            console.log(err);
        } finally {
            this.working = false;
        }
    }
}
