import { bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Onboardings } from 'services/onboardings';
import { Security } from 'common/security';
import { OnboardingStep } from './onboarding-step';

export class OnboardingComplete extends OnboardingStep {
    static inject = [Element, Onboardings, Security, Router];
    @bindable status;
    @bindable step;

    _router;

    constructor(element, onboardings, security, router) {
        super(element, onboardings);
        this._security = security;
        this._router = router;
    }

    async complete() {
        await this.updateProgress(this.step);
        this._security.completeOnboarding();
        this._router.navigate('#/members', true);
    }
}
