import { Page } from 'common/ui';
import { Security } from 'common/security';
import { ROLE } from 'common/constants';

export class MyTeam {
    static inject = [Page, Security];

	currentView = 'chart';

	myTeamModel = null;
	myTeamView = '';

	constructor(page, security) {
		this.page = page;
        this._security = security;

        this.allowMapView = true;
	}

	activate(params) {
        if (params.view === 'map') this.teamChartView = 'map';
        else this.teamChartView = 'chart';
		this.selectView(params.view || this.currentView);
		this.page.track('members/my-team/' + this.currentView);
    }

	selectView(view) {
        switch (view) {
            case 'search':
                this.myTeamModel = {};
                this.myTeamView = PLATFORM.moduleName('./controls/search-members');
                this.loadChart = false;
                break;
            default:
                this.myTeamModel = {};
                this.myTeamView = undefined;
                view = 'chart';
                this.loadChart = true;
                break;
        }
		this.currentView = view;
    }

}
