export class OnboardingStep {
    _element;
    _onboardings;

    constructor(element, onboardings) {
        this._element = element;
        this._onboardings = onboardings;
    }

    async updateProgress(model) {
        try {
            const status = await this._onboardings.updateProgress(model);
            this.stepCompleted(status);
            return status;
        } catch (err) {
            console.log(err);
        }
    }

    stepCompleted(status) {
        this._element.dispatchEvent(new CustomEvent('completed', { bubbles: true, detail: status }));
    }
}
