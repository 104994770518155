import { computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { Security } from 'common/security';
import { Onboardings } from 'services/onboardings';
import { MenuController } from 'resources/base-classes/menu-controller';
import moment from 'moment';

export class OnboardingView extends MenuController {
    static inject = [EventAggregator, Router, Security, Onboardings];
    _router;
    _security;
    _onboardings;

    status;
    template;
    step;

    constructor(ea, router, security, onboardings) {
        super(ea, 'onboarding');
        this._router = router;
        this._security = security;
        this._onboardings = onboardings;
    }

    async activate() {
        try {
            this.status = await this._onboardings.myStatus();
            if (this.status.isComplete || this._security.agent.onboardingComplete) {
                this._security.completeOnboarding();
                this._router.navigate('#/members', true);
                return;
            }
            this._setActiveStep();
        } catch (err) {
            console.log(err);
        }
    }

    attached() {
        this.setMenu();
    }

    _setActiveStep() {
        this.step = this.status.template.steps.find(x => !x.isComplete);
        if (!this.step) {
            this._security.completeOnboarding();
            this._router.navigate('#/members', true);
        }
    }

    onStepCompleted(status) {
        this.status = status;
        this._setActiveStep();
    }

    async resetOnboarding() {
        try {
            await this._onboardings.reset(this._security.authenticatedMemberId);
            await this.activate();
            this.refreshOnboardingMemberId = moment().format();
        } catch (err) {
            console.log(err);
        }
    }
}
