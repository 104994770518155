import { bindable } from 'aurelia-framework';
import { Onboardings } from 'services/onboardings';
import { OnboardingStep } from './onboarding-step';

export class HaveLicense extends OnboardingStep {
    static inject = [Element, Onboardings];
    @bindable status;
    @bindable step;

    contentKey;
    needLicenseContentKey;
    showLicenseOptions = false;

    constructor(element, onboardings) {
        super(element, onboardings);
    }

    attached() {
        this.contentKey = `onboarding-have-license-${this.step.platformValue.toLowerCase()}`;
        this.needLicenseContentKey = `onboarding-need-license-${this.step.platformValue.toLowerCase()}`;
    }

    async haveLicense() {
        this.step.data = true;
        await this.updateProgress(this.step);
    }

    async needLicense() {
        this.showLicenseOptions = true;
    }

    back() {
        this.showLicenseOptions = false;
    }
}
